import '@mod-publisher/js/richcontent/all'; //support rich content (video, images)
import '@mod-system/js/wh/integration'; //activate WebHare features

// main CSS
import './tco.scss';

// dompack
import * as dompack from 'dompack';

dompack.onDomReady(() => {

});
